.clients {
    background-color: rgb(245, 245, 245);
    padding: 1rem 0;
}

.clients-con {
    width: 98%;
    overflow: hidden;
    margin: 0 auto 0;
}

.clients-div {
    display: flex;
    gap: 3rem;
    align-items: center;
    width: auto;
    animation: floatbg 40s cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
}
.clients-div img {
    opacity: 0.5;
    height: 100px;
    width: auto;
    filter: grayscale(100%);
}

.clients-div img:nth-child(4) {
    height: 50px;
}

.proposal-con {
    padding: 2rem 4rem;
    overflow: hidden;
}

.proposal-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 4rem auto 2.5rem;
}

.proposal-header h1{
    font-weight: 500;
    margin: 15px 0;
    font-size: 3.2em;
    width: 100%;
    align-self: center;
    line-height: 55px;
}

.proposal-header h2 {
    font-size: 23px;
    font-weight: 500;
    margin: 1rem 0 1rem;
    text-transform: uppercase;
    position: relative;
}

.proposal-header h2::after {
    width: 5rem;
    content: '';
    position: absolute;
    bottom: -30px;
    left: 0;
    border-bottom: 4px solid var(--orange);
}

.work-flow {
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    width: 100%;
}

.wf-1 {
    align-self: center;
    display: flex;
    width: 100%;
}

.gl {
    position: relative;
    width: 50%;
    height: auto;
    align-items: center;
}

.gl-right {
    visibility: visible;
}

.gl-right::after {
    border-radius: 100px;
    opacity: 0.8;
    content: '';
    background: url('../../img/Vector7.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(90deg);
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    z-index: -10;
  }

@keyframes floatbg {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(-350px);
    }
    100% {
        transform: translateX(0px);
    }
}
  
.gl-right::before {
    border-radius: 100px;
    content: '';
    background: url('../../img/ibr1.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
    opacity: 0.9;
    /* animation: floatbike 3s linear infinite; */
  }

@keyframes floatbike {
    0% {
        transform: translateX(-30px);
    }
    50% {
        transform: translateX(50px);
    }
    100% {
        transform: translateX(-30px);
    }
}

.gl-header {
    font-weight: 800;
    font-size: 40px;
    margin: 3rem 0 1rem;
    background-color: var(--black);
    padding: 20px 20px;
    border-radius: 3px;
    color: #fff;
    width: fit-content;
    box-shadow: -60px -30px 60px -25px rgb(78, 57, 0);
}

.gl-body {
    font-size: 20px;
    line-height: 30px;
    position: relative;
}

.gl-click {
    color: var(--black);
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 3rem 0;
    padding: 10px 20px;
    width: fit-content;
    border-left: 3px solid var(--black);
}

.gl-click-sm {
    color: var(--black);
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
    padding: 10px 20px;
    width: fit-content;
    border-left: 1px solid var(--black);
}

.gl-click-txt {
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.gl-click-txt-sm {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.prop-stats {
    position: relative;
    margin-top: 4rem;
}

.prop-stats-con {
    position: relative;
    padding: 0.1rem 0;
}

.prop-stats-con::after {
    opacity: 0.8;
    content: '';
    background: url('../../img/stats-bg1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    z-index: -10;
}
  
.prop-stats-con::before {
    content: '';
    background: rgb(248, 120, 0);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
    opacity: 0.9;
    /* animation: floatbike 3s linear infinite; */
}

.prop-stats-div {
    width: 60%;
    margin: 10rem auto;
    font-size: 40px;
    font-weight: 300;
    line-height: 50px;
    color: var(--blueCard);
    text-align: center;
}

.prop-stats-div span {
    font-weight: 700;
}

.prop-stats-div label {
    font-style: italic;
}








@media (max-width: 1280px) {
    .clients-div img {
        height: 125px;
    }
}

@media (max-width: 1130px) {
    .gl-click-txt {
        font-size: 15px;
    }
    .proposal-header h1 {
        font-size: 40px;
    }
    .proposal-header h2 {
        font-size: 20px;
    }
}

@media (max-width: 1130px) {
    .gl-body {
        font-size: 18px;
    }
    .clients-div img {
        height: 100px;
    }
}

@media (max-width: 1002px) {
    .gl-click-txt {
        font-size: 13px;
    }
    .wf-1 {
        display: block;
    }
    .gl {
        width: 100%;
    }
    .gl-right {
        height: 40rem;
    }
}

@media (max-width: 805px) {
    @keyframes floatbg {
        0% {
            transform: translateX(0px);
        }
        50% {
            transform: translateX(-400px);
        }
        100% {
            transform: translateX(0px);
        }
    }
}

@media (max-width: 750px) {
    @keyframes floatbg {
        0% {
            transform: translateX(0px);
        }
        50% {
            transform: translateX(-400px);
        }
        100% {
            transform: translateX(0px);
        }
    }
    .clients-div img:nth-child(5) {
        height: 100px;
    }
    .clients-div img:nth-child(4) {
        height: 30px;
    }
}

@media (max-width: 710px) {
    .proposal-con {
        padding: 1rem;
    }
    .proposal-header {
        margin-top: 2rem;
    }
    .proposal-header h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
    }
    .proposal-header h2 {
        font-size: 20px;
        line-height: 25px;
        margin: 0;
    }
    .proposal-header h2::after {
        bottom: -20px;
    }
    .prop-cta {
        font-size: 18px !important;
    }
    .gl-click {
        margin-top: 2rem;
    }
    .gl-body {
        font-size: 18px;
    }
    .gl-right {
        height: 30rem;
    }
    .gl-right::before {
        width: 140%;
        left: -80px;
    }
    .gl-right::after {
        width: 120%;
        left: -40px;
    }
    .prop-stats {
        margin-top: 1rem;
    }
    .prop-stats-div {
        font-size: 32px;
    }
    @keyframes floatbg {
        0% {
            transform: translateX(0px);
        }
        50% {
            transform: translateX(-450px);
        }
        100% {
            transform: translateX(0px);
        }
    }
}

@media (max-width: 635px) {
    @keyframes floatbg {
        0% {
            transform: translateX(0px);
        }
        50% {
            transform: translateX(-500px);
        }
        100% {
            transform: translateX(0px);
        }
    }
}

@media (max-width: 585px) {
    @keyframes floatbg {
        0% {
            transform: translateX(20px);
        }
        50% {
            transform: translateX(-600px);
        }
        100% {
            transform: translateX(20px);
        }
    }
    .clients-div {
        gap: 2rem;
    }
}

@media (max-width: 500px) {
    .clients {
        padding: 8px;
    }
    .gl-right {
        height: 20rem;
    }
    @keyframes floatbg {
        0% {
            transform: translateX(-20px);
        }
        50% {
            transform: translateX(-600px);
        }
        100% {
            transform: translateX(-20px);
        }
    }
    .clients {
        padding: 0;
    }
    .clients-div img {
        height: 80px;
    }
    .clients-div img:nth-child(4) {
        height: 30px;
    }
    .clients-div img:nth-child(3) {
        height: 120px;
    }
}
