:root
{
  --yellow: #F5C32C;
    --orange : #FCA61F;
    --black : #1b1b1d;
    --header-black : #000000;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(171, 119, 204);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
    --baloo-2: 'Baloo 2', cursive;
    --golos: 'Golos Text', sans-serif;
    --maven: 'Maven Pro', sans-serif;
    --montserrat: 'Montserrat Alternates', sans-serif;
    --pontano: 'Pontano Sans', sans-serif;
    --ubuntu: 'Ubuntu', sans-serif;
}

* {
  font-family: var(--golos);
  font-weight: normal;
  line-height: 20px;
}

img {
  -webkit-tap-highlight-color: transparent !important;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent !important;
}

ul {
  list-style-type: none;
}

.h-input {
  display: none;
}





.App{
    overflow: hidden;
    color: var(--black);
    height: 40rem;
    background-position: center;
    background-size: cover;
    position: relative;
}

.body {
  color: var(--black);
  width: 100%;
  overflow: hidden;
}

.App::after {
  opacity: 0.4;
  content: '';
  background: url('./img/homebg.png');
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: -10;
}

.App::before {
  content: '';
  background-color: var(--header-black);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
  opacity: 0.9;
}

.button {
  border-radius: 2px;
  border: 2px solid var(--orange);
  width: max-content;
  color: var(--orange);
  font-size: 16px;
  padding: 11px 26px;
  background: transparent;
  position: relative;
  cursor: pointer;
}

.button::before {
  color: var(--orange);
  background-color: var(--orange);
  cursor: auto;
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.button:hover {
  color: white;
  border: 2px solid var(--orange);
}

.button:hover::before {
  content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}

.button-w {
  border-radius: 2px;
  border: 2px solid #fff;
  width: max-content;
  color: #fff;
  font-size: 16px;
  padding: 11px 26px;
  background: transparent;
  position: relative;
  cursor: pointer;
}

.button-w::before {
  color: #fff;
  background-color: #fff;
  cursor: pointer;
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.6s ease-in-out;
}

.button-w:hover {
  color: var(--black);
  border: 2px solid #fff;
}

.button-w:hover::before {
  content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}

.button-b {
  border-radius: 0px;
  width: max-content;
  color: var(--black);
  background: transparent;
  position: relative;
  cursor: pointer;
}

.button-b::before {
  color: #fff;
  background-color: var(--black);
  cursor: pointer;
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.6s ease-in-out;
}

.button-b:hover {
  color: #fff;
}

.button-b:hover::before {
  content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}



/* ScrollBar */

::-webkit-scrollbar {
  width: 5px;
  height: 2px;
  display: block;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1b1b1d; 
  opacity: 0.9;


}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #F5C32C;
  border-radius: 3px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--orange); 
}













@media (max-width: 600px) {
  .button-w {
    border-width: 1px;
  }
}

@media (max-width: 490px) {
  .button-b, .button-b::before, .button-w, .button-w::before {
    cursor: auto;
  }
}