
.blog-con {
    margin-top: 8rem;
    width: 100%;
    overflow: hidden;
}

.bcd-header {
    margin: 0 auto;
    padding: 3rem 0;
    font-size: 3rem;
    font-weight: 600;
    width: fit-content;
    line-height: 3rem;
}

.bcd-cards {
    display: block;
    column-count: 4;
    break-inside: avoid;
    gap: 1rem;
    width: 1288px;
    margin: 2rem auto 4rem;
}

.bcd-card {
    width: auto;
    height: 26rem;
    margin-bottom: 3rem;
}

.bcd-img {
    height: 12rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.bcd-details {
    height: 40%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.bcd-details-body {
    padding: 1rem 0.7rem 0.5rem;
    background: transparent;
    font-size: 20px;
    font-weight: 500;
    color: var(--black);
    border: none;
    z-index: 10;
    position: relative;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: var(--boxShadow);
}

.bcddb-header {
    font-size: 20px;
    font-weight: 600;
    height: 77px;
    text-transform: capitalize;
    line-height: 1.2;
    overflow: hidden;
}

.bcddb-body {
    font-size: 14px;
    font-weight: 300;
    height: 90px;
    padding: 15px 0;
}

.bcddb-body p {
    margin: 0;
    max-height: 0;
}

.bcddb-stats {
    font-size: 12px;
    color: rgb(163, 163, 163);
    display: flex;
}

.bcddb-stats .date {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}

.bcddb-stats .views {
    width: 50%;
    display: flex;
    gap: 0.3rem;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
}







@media (max-width: 1300px) {
    .bcd-cards {
        width: 1000px;
        column-count: 4;
    }
    .bcd-card:nth-child(4) {
        display: none;
    }
}

@media (max-width: 1020px) {
    .bcd-cards {
        width: 890px;
    }
}

@media (max-width: 900px) {
    .bcd-cards {
        width: 700px;
        column-count: 2;
    }
    .bcd-card:nth-child(4) {
        display: block;
    }
    .bcd-header {
        font-size: 2.5rem;
        line-height: 2.5rem;
    }
    .blog-con {
        margin-top: 5rem;
    }
}

@media (max-width: 720px) {
    .bcd-cards {
        width: 600px;
    }
    .bcd-header {
        font-weight: 500px;
    }
}

@media (max-width: 610px) {
    .bcd-cards {
        column-count: 1;
        width: 324px;
    }
    .bcd-header {
        font-size: 2rem;
        line-height: 2.1rem;
        padding: 1rem;
    }
    .bcddb-header {
        height: auto;
    }
}