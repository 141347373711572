.convince {
    margin: 9rem 0 0;
}

.convince-div-header h1 {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
}

.convince-div-header h2 span {
    border-bottom: 4px solid var(--orange);
    font-weight: inherit;
}

.convince-div-header h2 {
    text-align: center;
    font-weight: 400;
    font-size: 1.7rem;
}

.convince-cards {
    width: 90%;
    height: fit-content;
    display: block;
    margin: 4rem auto;
    column-count: 2;
    gap: 2rem;
    break-inside: avoid;
}

.con-card {
    width: auto;
    height: auto;
    border: 1px solid rgb(158, 158, 158);
    padding: 2em;
    margin-bottom: 2rem;
    border-radius: 0.5rem;
}

.con-card-text h3 {
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
}

.con-card-text-body {
    font-weight: 200;
    color: rgb(129, 129, 129);
    line-height: 25px;
}

.con-card-text li {
    list-style-type: circle;
    line-height: 24px;
}

.con-card-text hr {
    margin: 1.5rem 0 1rem;
    color: rgb(129, 129, 129);
}

.con-card-cta {
    visibility: visible;
}

.glc-click {
    color: var(--black);
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1.5rem 0 0;
    padding: 10px 20px;
    width: fit-content;
}

.glc-click-txt {
    font-size: 16px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.cct {
    min-height: 15rem;
}

/* .con-card:nth-child(1) {
    border-top-left-radius: 20px;
}

.con-card:nth-child(2) {
    border-bottom-left-radius: 20px;
}

.con-card:nth-child(3) {
    border-top-right-radius: 20px;
}

.con-card:nth-child(4) {
    border-bottom-right-radius: 20px;
} */









@media (min-width: 1286px) {
    .cct {
        min-height: 275px;
    }
}

@media (max-width: 1285px) {
    .cct {
        min-height: 315px;
    }
}

@media (max-width: 1200px) {
    .cct {
        min-height: 330px;
    }
}

@media (max-width: 1161px) {
    .cct {
        min-height: 350px;
    }
}

@media (max-width: 1130px) {
    .cct {
        min-height: 370px;
    }
}

@media (max-width: 1002px) {
    .cct h3 {
        font-size: 20px;
    }
    .con-card-text {
        font-size: 14px;
    }
    .convince-div-header h1 {
        font-size: 42px;
        line-height: 35px;
    }
    .convince-div-header h2 {
        font-size: 25px;
    }
}

@media (max-width: 900px) {
    .convince {
        margin-top: 3rem;
    }
    .convince-cards {
        column-count: 1;
        margin-top: 2rem;
    }
    .cct {
        min-height: auto;
    }
    .convince-div-header {
        width: 100%;
        padding: 1em 0;
    }
    .convince-div-header h1 {
        font-size: 32px;
    }
    .convince-div-header h2 {
        font-size: 18px;
    }
}

@media (max-width: 500px) {
    .convince-cards {
        margin: 1rem auto 1rem;
    }
}