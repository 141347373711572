.intro {
    padding: 5rem 3.5rem 2rem;
    display: flex;
    width: -webkit-fill-avaliable;
    overflow: hidden;    
    justify-content: center;
}

.intro-header {
    margin-top: 0rem;
    font-size: 80px;
    font-weight: 800;
    line-height: 76px;
    letter-spacing: -4px;
    font-family: var(--montserrat);
    color: #fff;
    word-spacing: 4px;
    text-align: center;
}

.intro-body {
    color: var(--black);
    background-color: var(--orange);
    width: fit-content;
    border-radius: 3px;
    padding: 8px;
    font-size: 28px;
    font-weight: 400;
    margin: 20px auto 0;
    letter-spacing: 3px;
    line-height: 30px;
}

.in-left {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-top: 12rem;
}

.in-left-con {
    width: fit-content;
}

.in-left-click {
    color: #fff;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
    padding: 10px 20px;
    width: fit-content;
}

.in-left-click-txt {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.in-left-arrow {
    font-size: 30px !important;
}

.in-right {
    flex: 1;
}

.intro-img {
    width: 100%;
    height: auto;
}

.img {
    width: 100%;
    height: auto;
}









@media (max-width: 1002px) {
    .in-left-arrow {
        font-size: 18px !important;
    }
    .intro-header {
        font-size: 65px;
        line-height: 65px;
    }
}

@media (max-width: 805px) {
    .intro-body {
        font-size: 24px;
        line-height: 25px;
    }
}

@media (max-width: 772px) {
    .in-left {
        padding-top: 9rem;
    }
    .intro-body {
        font-size: 20px;
        margin-left: 0;
        margin-right: 0;
        letter-spacing: 2px;
        line-height: 20px;
    }
    .in-left-click {
        margin-right: 0;
        margin-left: 0;
    }
    .in-left-click-txt {
        font-size: 18px;
    }
}

@media (max-width: 620px) {
    .intro-header {
        font-size: 50px;
        line-height: 53px;
        letter-spacing: -2px;
        text-align: left;
    }
}

@media (max-width: 500px) {
    .intro {
        padding-left: 2rem;
        padding-right: 2rem;
        height: fit-content;
    }
    .in-left {
        padding-top: 7rem;
        padding-bottom: 5rem;
    }
    .in-left-click-txt {
        font-size: 16px;
    }
}