

.top-img {
    background-color: var(--black);
    overflow: hidden;
}

.top-img img {
    width: 100%;
    height: auto;
    margin-top: -20rem;
}

.about-us-con {
    padding-top: 10rem;
    padding-bottom: 5rem;
    margin-top: -20rem;
    position: relative;
    background-color: #fff;
    z-index: 100;
}

.privacy-con {
    padding-top: 10rem;
    padding-bottom: 5rem;
    margin-top: 0rem;
    position: relative;
    background-color: #fff;
    z-index: 100;
}

.pcd {
    padding: 0 25px;
}

.pcd-header {
    font-size: 35px;
    font-weight: 800;
}

.eff-date {
    font-size: 16px;
    line-height: 25px;
}

.pcd-body {
    margin-top: 40px;
    padding: 5px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 60px;
}

.eff-bold {
    font-weight: 600;
    font-size: 20px;
}

.eff-list {
    list-style-type: disc;
    font-size: 16px;
}
.eff-list li {
    line-height: 25px;
}

.a-socials {
    color: var(--black);
    width: fit-content;
    margin: 5rem auto 1rem;
}

.a-socials-h {
    font-size: 30px;
    font-weight: 600;
    width: fit-content;
    margin: 0 auto 1.5rem;
}

.a-socials-b ul {
    display: flex;
    gap: 2rem;
    padding: 0;
}

.a-socials-b ul a li {
    color: var(--black);
    text-decoration: none;
    font-size: 45px;
}

.a-socials-b ul a li:hover {
    color: var(--orange);
    transform: scale(1.2);
}








@media (max-width: 1100px) {
    .top-img img, .privacy-con {
        margin-top: -15rem;
    }
}


@media (max-width: 1000px) {
    .privacy-con {
        padding-top: 6rem;
    }
    .pcd-header {
        font-weight: 700;
        line-height: 40px;
    }
}

@media (max-width: 750px) {
    .top-img img, .privacy-con {
        margin-top: -10rem;
    }
}

@media (max-width: 550px) {
    .top-img img {
        margin-top: -2rem;
    }
    .privacy-con {
        margin-top: -5rem;
    }
}