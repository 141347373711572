
.sales {
    padding-top: 8.2rem;
    background-color: #ee9b00;
    padding-bottom: 5rem;
}

.scon {
    padding: 3rem 3rem;
    width: 1000px;
    height: auto;
    margin: 0 auto;
    display: flex;
    gap: 2rem;
    border-radius: 15px;
    color: #fff;
}

.sct-header {
    font-size: 30px;
    font-weight: 700;
    font-family:  var(--ubuntu);
    margin-bottom: 1.5rem;
}

.scon-2 {
    margin-top: 1rem;
}

.scon-steps {
    font-size: 18px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    padding: 20px 0;
}

.scon-steps label {
    font-family: var(--ubuntu);
}

.scon-steps-mob {
    display: none;
}

.scon-steps div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}











.scon-right {
    padding-left: 30px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.scon-form-div {
    background-color: #fff;
    padding: 2rem;
    color: var(--black);
    width: 300px;
    border-radius: 15px;
    height: fit-content;
}

.sc-form {
    height: 387px;
    overflow: hidden;
    display: flex;
}

.fm-card {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    overflow: hidden;
    position: relative;
    transition: all 0.3s linear;
}

.sc-form .fields {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-width: 300px;
}

.fields em {
    font-size: 14px;
    color: rgb(139, 139, 139);
}

.sc-form .fields .input {
    padding: 10px 15px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border 0.3s linear;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: rgb(99, 99, 99);
    overflow: hidden;
}

.sc-form .fields .input:focus-within {
    border-color: #1167c9;
}

.input input {
    border: none;
    outline: none;
    margin-left: 1rem;
    width: 230px;
}

.scon-btn {
    display: flex;
    justify-content: flex-end;
}

.scon-btn, .scon-btn2 {
    position: absolute;
    bottom: 0;
    width: 300px;
}

.sc-next {
    background-color: #1167c9;
    color: #fff;
    font-size: 14px;
    padding: 7px 15px;
    border-radius: 5px;
    width: fit-content;
}

.sc-prev {
    background-color: #11c9a1;
    color: #fff;
    font-size: 14px;
    padding: 7px 15px;
    border-radius: 5px;
    width: fit-content;
}

.scon-btn2 {
    display: flex;
    justify-content: space-between;
}

.sc-next:hover {
    background-color: #09488f;
    cursor: pointer;
}

.sc-prev:hover {
    background-color: #049e7d;
    cursor: pointer;
}

.input-chck {
    font-size: 12px;
}

.input-chck label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.3rem;
}

.cn {
    margin-top: 1rem;
    padding: 1rem 0.8rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    color: rgb(65, 65, 65);
    height: 200px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.cn-name {
    display: flex;
    gap: 0.5rem;
}

.cn-name label {
    font-weight: 500;
    min-width: 83px;
}

.sc-submit {
    border: none;
    background-color: var(--orange);
    color: #fff;
    font-size: 14px;
    padding: 7px 15px;
    border-radius: 5px;
    width: fit-content;
}

.sc-submit:hover {
    background-color: #eb7d00;
    cursor: pointer;
}










@media (max-width: 1105px) {
    .scon {
        width: 800px;
    }
}

@media (max-width: 1000px) {
    .sales {
        padding-top: 6.5rem;
    }
}

@media (max-width: 905px) {
    .scon {
        width: 600px;
        flex-direction: column;
    }
    .scon-2 {
        display: flex;
        gap: 1.5rem;
        flex-wrap: wrap;
        align-items: center;
    }
    .scon-steps {
        gap: 0.5rem;
    }
    .scon-steps label {
        display: none;
    }
    .scon-steps-mob {
        display: block !important;
    }
    .scon-right {
        padding-left: 0;
    }
    .scon-form-div {
        margin: 0 auto;
    }
}

@media (max-width: 705px) {
    .scon {
        width: -webkit-fill-available;
        padding: 3rem 1rem;
    }
    .scon-steps {
        padding: 0;
    }
    .scon-2 {
        margin-top: 1.5rem;
    }
    .scon-right {
        margin-top: 1.7rem;
    }
}

@media (max-width: 500px) {
    .scon-form-div {
        padding: 1.5rem 1rem;
    }
    .sc-form {
        height: 420px;
    }
}