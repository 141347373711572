
.footer-con {
    background-color: var(--black);
    padding-bottom: 8rem;
}

.fcd {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-bottom: 3.5rem;
    border-bottom: 1px solid #ccc;
}

.fcd-left {
    width: 65%;
    display: flex;
    justify-content: space-between;
}

.fcd-left-l {
    width: 45%;
}

.fcdll-header {
    width: 12rem;
}

.fcdll-img {
    width: 100%;
    height: auto;
    margin-top: -0.5rem;
}

.fcdll-body {
    color: #fff;
    font-size: 12px;
    margin: -2rem 0 5rem;
}

.f-socials {
    color: #fff;
}

.f-socials-h {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.f-socials-b ul {
    display: flex;
    gap: 1rem;
    padding: 0;
}

.f-socials-b ul a li {
    color: #fff;
    text-decoration: none;
    font-size: 25px;
}

.f-socials-b ul a li:hover {
    color: var(--orange);
    transform: scale(1.2);
}

.fcd-left-r {
    color: #fff;
    display: flex;
    width: 45%;
    justify-content: space-between;
    margin-top: 5rem;
}

.fcdlr-left-header {
    margin-bottom: 2rem;
}

.fcdlr-left-body {
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.2rem;
}

.link-sm {
    color: #fff;
    text-decoration: none;
}

.footer-services {
    color: #fff;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
    padding: 10px 10px;
    width: fit-content;
}

.footer-services-txt {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    z-index: 2;
}

.fs-arrow {
    font-size: 20px !important;
    z-index: 2;
}

.button-w-sm {
    border: 1px solid #fff;
    border-radius: 2px;
    width: max-content;
    color: #fff;
    font-size: 16px;
    padding: 11px 26px;
    background: transparent;
    position: relative;
    cursor: pointer;
}

.button-w-sm::before {
    color: #fff;
    background-color: #fff;
    cursor: pointer;
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    transition: 0.4s ease-in-out;
}
  
.button-w-sm:hover {
    color: var(--black);
    border: 1px solid #fff;
}
  
.button-w-sm:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
}

.fcd-right {
    color: #fff;
    margin-top: 5rem;
    width: 30%;
}

.f-nwsl-hh {
    font-size: 15px;
    margin-top: 2rem;
}

.nwsl {
    font-size: 15px;
}

.f-name {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.1rem;
    flex-wrap: wrap;
    margin: 1rem 0;
}

.f-txt {
    width: 4rem;
}

.f-name input {
    padding: 5px 10px;
    font-size: 15px;
    outline: none;
}

.submit {
    border: none;
    padding: 8px 20px;
    font-size: 15px;
    background-color: var(--orange);
    font-weight: 500;
    transition: 0.3s linear;
    cursor: pointer;
}

.submit:hover {
    box-shadow: 0px 0px 10px -1px var(--orange);
}

.copy {
    color: #fff;
    width: fit-content;
    margin: 1rem auto;
}










@media (max-width: 1100px) {
    .fcd {
        display: block;
    }
    .fcd-left {
        width: 100%;
    }
    .fcd-right {
        width: 50%;
        margin: 5rem auto 2rem;
    }
    .copy {
        font-size: 12px;
    }
}

@media (max-width: 822px) {
    .fcd-right {
        width: 366px;
    }
}

@media (max-width: 750px) {
    .fcd-left {
        display: block;
    }
    .fcd-left-r {
        width: 400px;
        margin: 5rem auto 0;
    }
    .fcd-left-l {
        width: 400px;
        margin: 0 auto;
    }
}

@media (max-width: 450px) {
    .fcd {
        padding: 1rem;
    }
    .fcd-right {
        width: 100%;
    }
    .fcd-left-l {
        width: 100%;
    }
    .fcd-left-r {
        width: 100%;
    }
    .copy {
        font-size: 12px;
    }
}