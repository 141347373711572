

/* Each service page */

.no-b {
    border-radius: 5px !important;
}

.b-fancy {
    padding-bottom: 8rem;
    color: #fff;
    position: relative;
}

.b-fancy::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -10;
    background: rgb(241, 137, 0);
    clip-path: polygon(100% 1%, 100% 49%, 34% 99%, 0 76%, 0 0);
}

.b-fancy .gl-click-sm {
    color: #fff !important;
    border: none !important;
    background-color: var(--black);
    margin-top: 2rem !important;
    transition: all 0.2s linear;
}

.b-fancy .gl-click-sm:hover {
    color: var(--black) !important;
    background-color: #fff !important;
    padding: 10px 30px;
}

.serv-header {
    font-size: 60px;
    font-weight: 600;
    line-height: 60px;
    margin-top: 0;
    text-transform: capitalize;
}

.serv-body {
    font-size: 20px;
    line-height: 28px;
    font-style: italic;
    margin-bottom: 1rem;
}


/* Each service page ends */




.services-div {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
}

.services-header {
    display: flex;
    position: relative;
    width: fit-content;
    font-size: 3rem;
    font-weight: 700;
    margin: 5rem auto 5rem;
    text-transform: capitalize;
}

.services-header::after {
    content: '';
    width: 4rem;
    height: 100%;
    border-bottom: 6px solid var(--orange);
    position: absolute;
    bottom: -30px;
    left: 0;
}

.services-body {
    width: -webkit-fill-available;
}

.services-cards {
    display: block;
    column-count: 3;
    break-inside: avoid;
    gap: 3rem;
    width: 1100px;
    margin: 2rem auto;
}

.service-card {
    width: auto;
    height: auto;
    margin-bottom: 3rem;
}

.service-card a {
    text-decoration: none;
}

.sc-img {
    height: 23rem;
    border-radius: 0.5rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}

.sc-details {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.sc-details::after {
    height: 100%;
    width: 100%;
    content: '';
    background: var(--black);
    opacity: 0.9;
    position: absolute;
    transition: 0.5s ease-in-out;
}

.sc-details:hover::after {
    opacity: 1;
}

.sc-details-body {
    padding: 1.5rem 2rem;
    background: transparent;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    border-radius: 3px;
    border: 3px solid #fff;
    z-index: 10;
    position: relative;
}

.sc-details-body::before {
    color: var(--black);
    background-color: #fff;
    cursor: pointer;
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.6s ease-in-out;
  }

  .sc-details:hover .sc-details-body::before {
      width: 100%;
  }

  .sc-details:hover .sc-details-body {
      color: var(--black);
  }

.service-btn {
    margin: 0 auto;
    width: fit-content;
}







@media (max-width: 1110px) {
    .services-cards {
        width: 800px;
        gap: 2rem;
        column-count: 2;
    }
    .services-header {
        font-size: 2.5rem;
    }
    .service-card {
        margin-bottom: 2rem;
    }
}

@media (max-width: 810px) {
    .services-cards {
        width: 650px;
    }
    .sc-details-body {
        border-width: 1px;
        font-weight: 300;
    }
    .sc-details {
        padding: 0 1rem;
    }
    .services-header {
        font-weight: 600;
    }
}

@media (max-width: 720px) {
    .b-fancy::before {
        clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0 0);
    }
}

@media (max-width: 672px) {
    .services-cards {
        width: 90%;
        column-count: 1;
    }
    .services-header {
        font-weight: 600;
        font-size: 2.5rem;
        width: 90%;
        line-height: 2.5rem;
    }
    
}