

.acd {
    display: flex;
    gap: 1rem;
    width: 1150px;
    margin: 4rem auto 2rem;
}

.acd-left {
    width: 50%;
    padding: 3rem 3rem;
    box-shadow: var(--boxShadow);
    border-radius: 1rem;
}

.acd-left-body {
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 2rem;
}

.acd-right {
    width: 50%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
}

.acd-img {
    width: 100%;
    height: auto;
    border-radius: 76% 24% 63% 37% / 29% 31% 69% 71% ;
    box-shadow: var(--boxShadow);
}

.acd-right::before {
    border-radius: 100px;
    content: '';
    background: url('../../img/about5.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
    opacity: 0.9;
  }










  @media (max-width: 1100px) {
      .acd {
          width: 900px;
      }
      .acd-left-body {
          font-size: 18px;
          line-height: 28px;
      }
      .gl-click-txt-sm {
          font-size: 18px;
      }
  }

  @media (max-width: 870px) {
      .acd {
          display: block;
          width: 90%;
          margin: 3rem auto 0;
      }
      .acd-left {
          width: auto;
      }
      .acd-right {
          width: 300px;
          height: 300px;
          margin: 2rem auto 0;
      }
  }

  @media (max-width: 500px) {
      .acd-left {
          padding: 3rem 1rem;
      }
  }