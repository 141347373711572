
.reviews-con {
    background-color: var(--black);
    border-top: 10px solid var(--orange);
    height: fit-content;
    margin: 6rem 0 1rem;
}

.reviews-header {
    text-align: center;
    font-size: 26px;
    font-weight: 200;
    color: #fff;
    padding: 4rem 0;
}

.rs-left {
    width: 235px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}

.rs-img {
    width: auto;
    height: auto;
    margin: 0 auto;
}

.rs-img img {
    width: 16rem;
    height: 16rem;
    border-radius: 50%;
}

.rs-right {
    width: 816px;
}




/* Slider animation */

@keyframes reviews {
    0% {
        left: 0;
    }
    12.28% {
        left: 0;
    }
    14.28% {
        left: -100%;
    }
    26.56% {
        left: -100%;
    }
    28.56% {
        left: -200%;
    }
    40.84% {
        left: -200%;
    }
    42.84% {
        left: -300%;
    }
    55.12% {
        left: -300%;
    }
    57.12% {
        left: -400%;
    }
    69.4% {
        left: -400%;
    }
    71.4% {
        left: -500%;
    }
    83.68% {
        left: -500%;
    }
    85.68% {
        left: -600%;
    }
    98% {
        left: -600%;
    }
    100% {
        left: -700%
    }
}

/* Slider animation */




.reviews-slider {
    overflow: hidden;
    width: 800%;
    display: flex;
    position: relative;
    left: 0;
    margin: 0;
    animation: 88s reviews infinite;
}

.reviews-slider-con {
    width: 12.5%;
}

.reviews-slide {
    display: flex;
    justify-content: space-between;
    width: 1150px;
    margin: 0 auto;
    padding: 0 0 5rem;
}

.rs-header {
    margin: 0 0 2rem;
    font-size: 35px;
    line-height: 50px;
    font-weight: 600;
    color: #fff;
    font-family: var(--golos) !important;
}

.rs-body {
    font-size: 20px;
    font-weight: 300;
    line-height: 40px;
    color: #fff;
    font-family: var(--golos) !important;
}

.rs-header span {
    color: #fff;
    transform: rotate(180deg);
    margin-right: 2rem;
    font-size: 80px;
}

.rs-author {
    font-size: 25px;
    font-weight: 400;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-family: var(--ubuntu) !important;
}

.rs-author span {
    color: #fff;
    font-size: 80px;
    display: flex;
    margin-top: -0.6rem;
}







@media (max-width: 1150px) {
    .rs-right {
        width: 700px;
    }
    .reviews-slide {
        width: 1000px;
    }
}

@media (max-width: 1005px) {
    .reviews-slide {
        display: block;
        width: 705px;
    }
    .rs-left {
        margin: 0 auto 2rem;
    }
    .rs-right {
        margin: 0 auto;
    }
    .rs-header {
        font-size: 30px;
    }
    .rs-body {
        font-size: 18px;
    }
}

@media (max-width: 710px) {
    .rs-header {
        font-size: 26px;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 25px;
        padding: 0 20px;
    }
    .rs-header span, .rs-author span {
        font-size: 50px;
    }
    .rs-body {
        font-size: 16px;
        line-height: 28px;
        padding: 0 20px;
    }
    .rs-author {
        font-size: 20px;
        padding: 0 20px;
    }
    .rs-right {
        width: 100%;
    }
    .reviews-slide {
        width: 100%;
    }
    .rs-left {
        width: auto;
    }
    .reviews-header {
        padding: 30px 0;
    }
    .rs-img img {
        width: 12rem;
        height: 12rem;
        border-radius: 50%;
    }
}

@media (max-width: 500px) {
    .rs-left {
        width: auto;
    }
    .rs-img img {
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
    }
}