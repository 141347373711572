


.support-con {
    padding-top: 10rem;
    padding-bottom: 8rem;
}

.spcd-header {
    width: fit-content;
    margin: 0 auto 0;
    font-size: 25px;
    padding: 0 1.5rem;
    line-height: 30px;
}

.su-form-div {
    width: fit-content;
    margin: 5rem auto 6rem;
    padding: 2rem;
    box-shadow: var(--boxShadow);
    border-radius: 10px;
}

.su-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.name-div {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    align-items: center;
}

.name-div label {
    width: 4rem;
}

.txt-input {
    padding: 5px 10px;
    outline: none;
    width: 14rem;
}

.su-submit {
    padding-top: 1rem;
    display: flex;
    justify-content: flex-end;
}









@media (max-width: 440px) {
    .support-con {
        padding-top: 8rem;
    }
    .spcd-header {
        font-size: 20px;
        line-height: 25px;
    }
    .su-form-div {
        width: auto;
    }
    .su-form {
        gap: 2rem;
    }
    .name-div {
        flex-direction: column;
        gap: 1rem;
    }
    .txt-input {
        width: -webkit-fill-available;
    }
}