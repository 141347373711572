.nav {
    position: fixed;
    width: -webkit-fill-available;
    background-color: #fff;
    z-index: 10000;
}

.menu-bar {
    display: none;
}

.mobile {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 100;
    padding-top: 0.5rem;
    background-color: #fff;
    transition: all 0.3s linear;
    overflow: hidden;
    user-select: none !important;
    display: none;
}

.mob-close {
    display: flex;
    justify-content: space-between;
    padding-right: 1rem;
    padding-left: 1rem;
    height: 6vh;
    align-items: center;
}

.logo-sm {
    height: 100%;
}

.logo-sm-img {
    height: 100%;
    width: auto;
    outline: none;
    user-select: none;
}

.mob-menu {
    height: auto;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.mobile ul {
    padding-left: 0;
    width: 288px;
}

.mobile ul li {
    padding: 20px 20px;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    gap: 1rem;
    outline: none;
    user-select: none;
}

.bold {
    font-weight: 500;
}

.mobile ul li span {
    font-weight: 600 !important;
    color: var(--orange);
}

.mobile ul li a {
    text-decoration: none;
    color: var(--black);
}

.sub {
    margin-top: -1rem;
    margin-bottom: 1rem;
    transition: all 0.3s linear;
    overflow: hidden;
}

.sub li {
    padding: 7px 20px !important;
    text-transform: capitalize !important;
}

.mob-socials {
    padding: 0 20px 5rem;
    max-width: 250px;
    overflow: hidden;
}

.mob-socials ul {
    width: 100%;
    display: flex;
    gap: 1rem;
}

.mob-socials ul li {
    font-size: 24px !important;
    padding: 0 !important;
}

.mob-socials ul a {
    text-decoration: none !important;
    color: var(--black);
}

.n-wrapper {
    padding: 1rem 3.5rem 0.5rem;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    color: #fff;
    position: relative;
    overflow: hidden;
}

.n-wrapper::before {
    content: '';
    position: absolute;
    background-color: var(--header-black);
    opacity: 0.9;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.n-left {
    flex: 1;
    align-items: center;
    display: flex;
    gap: 2rem;
    z-index: 10;
}

.n-name {
    width: 250px;
    font-size: 30px;
    font-weight: bold;
    z-index: 10;
    user-select: none;
    outline: none;
}

.n-name img {
    width: 100%;
    height: auto;
    margin-top: -0.5rem;
}

.n-right {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    z-index: 10;
}

.n-list {
    flex: 10;
}

.n-list>ul{
    display: flex;
    gap: 2rem;
    margin-right: 4rem;
    font-size: 16px;
}

.n-list > ul > li:hover, .n-list > ul > li > a:hover {
    cursor: pointer;
    color: var(--orange);
    transition: 0.3s ease-in-out;
    outline: none;
    user-select: none;
}

.n-list > ul > li > a {
    color: #fff;
    text-decoration: none;
}

.n-button {
    flex: 2;
}

.n-button span {
    z-index: 1;
}

.n-button i {
    display: none;
}








@media (max-width: 1150px) {
    .n-name {
        width: 200px;
    }
    .n-name img {
        margin-top: -0.4rem;
    }
}

@media (max-width: 1100px) {
    .n-button span {
        display: none;
    }
    .n-button i {
        display: block;
    }
}

@media (max-width: 1000px) {
    .n-name {
        width: 200px;
    }
    .n-name img {
        margin-top: 0rem;
    }
    .n-wrapper {
        padding-top: 0.5rem;
        height: 6vh;
    }
    .n-list {
        display: none;
    }
    .n-button {
        max-width: fit-content;
    }
    .n-right {
        justify-content: flex-end;
    }
    .menu-bar {
        display: flex;
    }
    .mobile {
        display: block;
    }
}

@media (max-width: 772px) {
    .n-wrapper {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .n-name img {
        cursor: auto;
    }
    .logo-sm-img {
        cursor: auto;
    }
}

@media (max-width: 550px) {
    .n-name {
        width: 180px;
    }
    .n-name img {
        margin-top: 0rem;
    }
    .n-wrapper {
        padding-top: 0.5rem;
        height: 6vh;
    }
    .button {
        border-width: 1px;
        padding: 8px 16px;
    }
}