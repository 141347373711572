.services-top-con {
    padding-top: 8em;
}

.stcd {
    display: flex;
    width: 1222px;
    margin: 2rem auto;
    justify-content: space-between;
}

.stcd-left {
    width: 40%;
    position: relative;
}

.stcd-left img {
    width: 100%;
    height: auto;
    position: relative;
    border-radius: 38% 62% 63% 37% / 27% 49% 51% 73% ;
}



.stcd-right {
    width: 53%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
}

.stcd-right p {
    margin: 0.5rem 0;
}

.stcd-header {
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
    padding: 0;
    margin: 0;
}








@media (max-width: 1235px) {
    .stcd {
        width: 1000px;
    }
    .stcd-header {
        font-size: 35px;
    }
    .stcd-right {
        width: 550px;
    }
    .serv-header {
        font-weight: 500;
        font-size: 50px;
        line-height: 50px;
    }
}

@media (max-width: 1020px) {
    .stcd {
        display: block;
        width: 100%;
    }
    .stcd-left {
        margin: 0 auto;
        width: 600px;
    }
    .stcd-right {
        margin: 3rem auto 0;
        width: 900px;
    }
    .serv {
        margin-bottom: 2rem;
    }
    .b-fancy {
        border-radius: 0;
        padding-bottom: 4rem;
        height: 650px;
        margin-bottom: 300px;
        padding-top: 4rem !important;
    }
    .serv-div .stcd-left {
        width: 90% !important;
        margin: 0 auto;
        height: auto;
    }
}

@media (max-width: 910px) {
    .stcd-right {
        width: 700px
    }
    .stcd-left {
        width: 450px;
    }
    .services-top-con {
        padding-top: 5rem;
    }
}

@media (max-width: 720px) {
    .stcd-right {
        width: 600px;
    }
    .stcd-left {
        width: 400px;
    }
    .serv-header {
        font-size: 45px;
        line-height: 45px;
    }
    .serv-body {
        font-size: 18px;
        line-height: 28px;
    }
    .b-fancy {
        height: 700px;
        margin-bottom: 200px;
    }
}

@media (max-width: 610px) {
    .stcd-right {
        width: auto;
        padding: 0 1rem;
    }
    .stcd-header {
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
    }
    .stcd-left {
        width: 90%;
    }
    .stcd-left img {
        border-radius: 5px;
    }
}

@media (max-width: 470px) {
    .b-fancy {
        height: 700px;
        margin-bottom: 120px;
    }
}